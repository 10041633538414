export const TYPE_SCHOOL = 2;
export const TYPE_INDIVIDUAL_USER_SCHOOL = 3;
export const TYPE_DEMO_SCHOOL = 7;
export const TYPE_TRIAL_SCHOOL = 8;
export const TYPE_STEM_TRIAL_SCHOOL = 11;
export const TYPE_VIRTUAL_SCHOOL = 4;
export const TYPE_BUILDUP_SCHOOL = 9;
export const TYPE_TEST_SCHOOL = 6;
export const SCHOOL_TYPES = [
  { value: TYPE_SCHOOL, text: 'School' },
  { value: TYPE_INDIVIDUAL_USER_SCHOOL, text: 'Individual User' },
  { value: TYPE_DEMO_SCHOOL, text: 'Demo School' },
  { value: TYPE_TRIAL_SCHOOL, text: 'Trial School' },
  { value: TYPE_STEM_TRIAL_SCHOOL, text: 'STEM Trial School' },
  { value: TYPE_VIRTUAL_SCHOOL, text: 'Virtual School' },
  { value: TYPE_BUILDUP_SCHOOL, text: 'Build Up School' },
  { value: TYPE_TEST_SCHOOL, text: 'Test School' },
];
export const TEMPLATE_TYPES = [...SCHOOL_TYPES];
export const EDITABLE_SCHOOL_TYPES = [
  { value: TYPE_SCHOOL, text: 'School' },
  { value: TYPE_INDIVIDUAL_USER_SCHOOL, text: 'Individual User' },
  { value: TYPE_VIRTUAL_SCHOOL, text: 'Virtual School' },
  { value: TYPE_BUILDUP_SCHOOL, text: 'Build Up School' },
  { value: TYPE_TEST_SCHOOL, text: 'Test School' },
];

export const SCHOOL_WEBSITES = [
  { id: 'gcsepod', label: 'gcsepod.com' },
  {
    id: 'eod-group',
    label: 'educationdemand.com',
    children: [
      { id: 'fepod', label: 'FE' },
      { id: 'scotpod', label: 'Scotland' },
      { id: 'eod', label: 'Generic' },
    ],
  },
];
