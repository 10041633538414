const mathInstructions = 'Format all mathematical expressions using LaTeX and wrap them with the <latex> tag. For example, if I provide an expression containing a fraction, you should return it as: <latex>\\frac{2}{3}</latex>. Do not use examples from the transcript for exercise questions.';
const historyInstructions = '1-\tAdd a difficulty level (on demand) for each question, based on these definitions:\n'
  + '\n'
  + 'a – Student is able to demonstrate generalised historical knowledge, using everyday language, and basic understanding of key features and characteristics. \n'
  + 'Construct a basic line of reasoning with some reference to second order concepts; Comprehend, to draw simple conclusions from sources to provide some investigation of historical issues, and from interpretations to identify similarities and differences.  \n'
  + 'c – Student is able to demonstrate mostly accurate and appropriate historical knowledge, using first order concepts, combined with a clear understanding of key features and characteristics; construct a coherent line of reasoning using second order concepts; analyse and provide some evaluation, to reach reasoned judgements, of \n'
  + 'a range of sources, in context, to investigate historical issues, and of interpretations and why they may differ. \n'
  + 'e - Student is able to demonstrate relevant and comprehensive knowledge, using first order concepts, combined with a sophisticated understanding of key features and characteristics; construct a convincing line of reasoning using second order concepts; critically analyse and evaluate a range of sources, in context, to investigate historical issues, and interpretations and why they may differ, to reach reasoned, substantiated judgements.  \n'
  + '\n'
  + '2-\tAdd ‘Assessment Objectives’ (on demand). Each question should be assigned one or more of the following assessment objectives. All relevant objectives should be assessed at least once, it is possible for a question to assess multiple objectives.”  \n'
  + '\n'
  + 'AO1: demonstrate knowledge and understanding of the key features and characteristics of the period studied. \n'
  + 'AO2: explain and analyse historical events and periods studied using second-order historical concepts. \n'
  + 'AO3: analyse, evaluate and use sources (contemporary to the period) to make substantiated judgements, in the context of historical events studied. \n'
  + 'AO4: analyse, evaluate and make substantiated judgements about interpretations (including how and why interpretations may differ) in the context of historical events studied \n';
export const ASSISTANT_ASSESS_SUBJECT_INSTRUCTIONS = {
  6060: mathInstructions,
  6080: mathInstructions,
  6093: mathInstructions,
  6094: mathInstructions,
  6095: mathInstructions,
  6105: mathInstructions,
  6117: mathInstructions,
  6121: mathInstructions,
  // history
  6018: historyInstructions,
  6111: historyInstructions,
  6118: historyInstructions,
};

export const ASSISTANT_ASSIGNMENT_SUBJECT_INSTRUCTIONS = {
  6060: mathInstructions,
  6080: mathInstructions,
  6093: mathInstructions,
  6094: mathInstructions,
  6095: mathInstructions,
  6105: mathInstructions,
  6117: mathInstructions,
  6121: mathInstructions,
};
