export default {
  namespaced: true,
  state: {
    loading: null,
    failed: false,
  },
  getters: {
    loading: state => state.loading,
    failed: state => state.loading && state.failed,
  },
  actions: {
    loading({ commit }) {
      commit('setLoading', true);
      commit('setFailed', false);
    },
    loaded({ commit }) {
      commit('setLoading', false);
    },
    failed({ commit }) {
      commit('setFailed', true);
    },
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },
    setFailed(state, value) {
      state.failed = value;
    },
  },
};
