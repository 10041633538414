import Vue from 'vue';

const moment = require('moment-timezone');
require('moment-duration-format');

const modifiers = {
  next: 1,
  current: 0,
  last: -1,
};

moment.defaultFormat = 'YYYY-MM-DD HH:mm:ss';

moment.prototype.getServiceYearStart = function getServiceYearStart(startMonth, modifier = 'current') {
  const date = moment(this).month(startMonth).startOf('month');
  if (this.isSameOrAfter(moment([this.year()]).month(startMonth), 'month')) {
    return date.add(0 + modifiers[modifier], 'year');
  }
  return date.add(-1 + modifiers[modifier], 'year');
};

moment.prototype.getServiceQuarter = function getServiceQuarter(startMonth) {
  const startOfYear = moment(this).getServiceYearStart(startMonth);

  return Math.floor(moment(this).diff(startOfYear, 'months') / 3) + 1;
};
moment.prototype.getServiceQuarterRange = function getServiceQuarterRange(startMonth, modifier = 'current') {
  const startOfYear = moment(this).getServiceYearStart(startMonth);
  const currentQuarter = moment(this).getServiceQuarter(startMonth);

  return {
    start: moment(startOfYear).add((currentQuarter - 1 + modifiers[modifier]) * 3, 'months').toDate(),
    end: moment(startOfYear).add((currentQuarter + modifiers[modifier]) * 3, 'months').toDate(),
  };
};

moment.prototype.getServiceYearEnd = function getServiceYearEnd(startMonth, modifier = 'current') {
  const date = moment(this).month(startMonth).startOf('month').subtract(1, 'day');
  if (this.isSameOrAfter(moment([this.year()]).month(startMonth), 'month')) {
    return date.add(1 + modifiers[modifier], 'year');
  }
  return date.add(0 + modifiers[modifier], 'year');
};


export function toDateRangeFromKey(rangeKey, {
  interval,
  mapper,
} = {
  interval: 1,
  mapper: undefined,
}) {
  let range;
  switch (rangeKey) {
    case 'last_ay':
      range = {
        start: moment().getServiceYearStart('September', 'last'),
        end: moment().getServiceYearEnd('September', 'last'),
      };
      break;
    case 'last_x_months':
      range = {
        start: moment().subtract(interval, 'month').startOf('day'),
        end: moment().endOf('day'),
      };
      break;
    case 'last_x_calendar_months':
      range = {
        start: moment().subtract(interval, 'month').startOf('month'),
        end: moment().subtract(1, 'month').endOf('month'),
      };
      break;
    case 'last_week':
      range = {
        start: moment().subtract(1, 'week').startOf('week'),
        end: moment().subtract(1, 'week').endOf('week'),
      };
      break;
    case 'last_x_days':
      range = {
        start: moment().subtract(interval, 'days').startOf('day'),
        end: moment().endOf('day'),
      };
      break;
    case 'this_month':
      range = {
        start: moment().startOf('month'),
        end: moment().endOf('day'),
      };
      break;
    case 'this_ay':
      range = {
        start: moment().getServiceYearStart('September', 'current'),
        end: moment().endOf('day'),
      };
      break;
  }

  if (range) {
    return mapper ? mapper(range) : range;
  }

  return undefined;
}


moment.prototype.isBeforeNow = function isBeforeNow() {
  return this.isBefore(moment());
};

Vue.prototype.moment = moment;

// Filters
Vue.filter('fromNow', time => (time ? moment.utc(time).local().fromNow() : ''));
Vue.filter('dateFormat', (time, format) => (time ? moment.utc(time).local().format(format) : '-'));
Vue.filter('fromFormat', (time, format) => moment.utc(time, format).local());
Vue.filter(
  'durationFormat',
  (seconds, format) => moment.duration(parseFloat(seconds), 'seconds').format(format, { trim: false }),
);

export default moment;
