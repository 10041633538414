import api from '@/api';

export default {
  namespaced: true,
  state: {
    subjects: {
      items: [],
      loading: false,
      lastFetchedAt: undefined,
    },
  },
  getters: {
    subjects: state => state.subjects.items,
    subjectsLoading: state => state.subjects.loading,
  },
  actions: {
    async fetchSubjects({ state, commit }, options = { force: false }) {
      if (state.subjects.loading
        || (state.subjects.items.length > 0
          && !options.force
          // reload if it's been more than 30 minutes since last fetch
          && (Date.now() < ((state.subjects.lastFetchedAt || 0) + (30 * 60 * 1000)))
        )
      ) {
        return;
      }
      commit('setLoadingStatus', { key: 'subjects', value: true });

      const subjects = await api.getPages('subjects', {
        params: {
          with: ['exam_boards'],
          per_page: 100,
        },
      }).then(data => data.map(s => ({
        ...s,
        label: s.name,
        exam_boards: s.exam_boards.map(eb => ({
          ...eb,
          label: eb.name,
        })),
      })));

      commit('setSubjects', subjects);
      commit('setLoadingStatus', { key: 'subjects', value: false });
    },
  },
  mutations: {
    setSubjects(state, data) {
      state.subjects.items = data;
      state.subjects.lastFetchedAt = Date.now();
    },
    setLoadingStatus(state, { key, value }) {
      state[key].loading = value;
    },
  },
};
