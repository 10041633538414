<template>
  <div id="app">
    <div>
      <LayoutHeader />
      <div class="container-fluid">
        <div class="row">
          <LeftNavigation v-if="!loading && !authError" />
          <section
            role="main"
            class="col-md-9 ml-sm-auto col-lg-10 px-4"
          >
            <section
              v-if="loading"
              class="vh-100 align-items-center d-flex justify-content-center"
            >
              <div
                v-if="loadingFailed"
                class="alert alert-danger"
                role="alert"
              >
                Failed loading page.
              </div>
              <font-awesome-icon
                v-else
                :icon="faCog"
                size="10x"
                spin
                class="text-secondary"
              />
            </section>
            <div
              v-else-if="authError"
              class="mt-5"
            >
              Session Expired! Please login again.
              <a
                href="/auth/start"
                class="d-block"
              >Login</a>
            </div>
            <router-view v-show="!loading && !authError" />
          </section>
        </div>
      </div>
      <LayoutFooter />

      <Toasts />
    </div>
  </div>
</template>
<script>
import {
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import LayoutHeader from './layout/LayoutHeader';
import LayoutFooter from './layout/LayoutFooter';
import LeftNavigation from './layout/LeftNavigation';
import Toasts from './components/Toasts';

export default {
  components: {
    Toasts,
    LeftNavigation,
    LayoutFooter,
    LayoutHeader,
  },
  data() {
    return {
      faCog,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    authError() {
      return this.$store.getters['auth/error'];
    },
    loading() {
      return this.$store.getters['pageLoading/loading'];
    },
    loadingFailed() {
      return this.$store.getters['pageLoading/failed'];
    },
  },
  created() {
    // listen for custom event to change route
    window.addEventListener('ON_ROUTE_CHANGE', this.routeHandler);
  },
  beforeDestroy() {
    window.removeEventListener('ON_ROUTE_CHANGE', this.routeHandler);
  },
  methods: {
    routeHandler(event) {
      const { action, config } = event.detail;
      if (action === 'replace') {
        this.$router.replace(config);
      } else {
        this.$router.push(config);
      }
    },
  },
};
</script>
