import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import pageLoading from './modules/pageLoading';
import toasts from './modules/toasts';
import clipboard from './modules/clipboard';
import collaborators from './modules/collaborators';
import content from './modules/content';
import staff from './modules/staff';
import metrics from './modules/metrics';
import contentTags from './modules/contentTags';
import featureFlags from './modules/featureFlags';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';
const store = new Vuex.Store({
  modules: {
    auth,
    pageLoading,
    toasts,
    clipboard,
    collaborators,
    content,
    metrics,
    staff,
    contentTags,
    featureFlags,
  },
  strict: debug,
});

store.waitFor = getter => new Promise((resolve) => {
  if (store.getters[getter] === undefined) {
    store.watch(
      (state, getters) => getters[getter],
      (value) => {
        if (value !== undefined) {
          resolve(value);
        }
      },
    );
  } else {
    resolve(store.getters[getter]);
  }
});

export default store;
