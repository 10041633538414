import _ from 'lodash';

const LOCAL_STORAGE_KEY_NAME = 'podmin_clipboard_v2';

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    items: state => (type = undefined) => {
      if (type) {
        return state.items.filter(item => typeof item === 'object' && item.type === type);
      }
      return state.items;
    },
  },
  actions: {
    fetch({ commit }) {
      commit('fetch');
    },
    add({ commit, dispatch }, payload) {
      dispatch('fetch');
      commit('addItem', payload);
      dispatch('fetch');
    },
    remove({ commit, dispatch }, payload) {
      commit('removeItem', payload);
      dispatch('fetch');
    },
    flush({ commit, dispatch }, type = undefined) {
      dispatch('fetch');
      commit('flush', type);
      dispatch('fetch');
    },
  },
  mutations: {
    fetch(state) {
      state.items = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_NAME) || '[]');
    },
    addItem(state, data) {
      if (Array.isArray(data) || typeof data === 'object') {
        state.items.unshift(_.cloneDeep(data));
      } else {
        state.items.unshift(data);
      }
      localStorage.setItem(LOCAL_STORAGE_KEY_NAME, JSON.stringify(state.items));
    },
    removeItem(state, data) {
      const index = state.items.indexOf(data);
      if (index !== -1) {
        state.items.splice(index, 1);
      }
      localStorage.setItem(LOCAL_STORAGE_KEY_NAME, JSON.stringify(state.items));
    },
    flush(state, type = undefined) {
      if (type) {
        state.items = state.items.filter(item => !(typeof item === 'object' && item.type === type));
      } else {
        state.items = [];
      }
      localStorage.setItem(LOCAL_STORAGE_KEY_NAME, JSON.stringify(state.items));
    },
  },
};
