export const ADDITIONAL_CONTENT_TYPE_WORKBOOK = 'workbook';
/** @deprecated */
export const ADDITIONAL_CONTENT_TYPE_PAST_PAPER = 'past_paper';
export const ADDITIONAL_CONTENT_TYPE_SAMPLE_PAPER = 'sample_paper';
export const ADDITIONAL_CONTENT_TYPE_GETTING_READY = 'getting_ready';
export const ADDITIONAL_CONTENT_TYPE_ADDITIONAL_CONTENT = 'additional_content';
export const ADDITIONAL_CONTENT_TYPES = [
  ADDITIONAL_CONTENT_TYPE_WORKBOOK,
  ADDITIONAL_CONTENT_TYPE_PAST_PAPER,
  ADDITIONAL_CONTENT_TYPE_SAMPLE_PAPER,
  ADDITIONAL_CONTENT_TYPE_GETTING_READY,
  ADDITIONAL_CONTENT_TYPE_ADDITIONAL_CONTENT,
];
