export const KEYCODE_C = 67;
export const KEYCODE_V = 86;
export const KEYCODE_A = 65;
export const KEYCODE_X = 88;
export const KEYCODE_ESCAPE = 27;
export const KEYCODE_ENTER = 13;
export const KEYCODE_F2 = 113;

export const CONTENT_TREE_KEY_CODES = [
  KEYCODE_C,
  KEYCODE_V,
  KEYCODE_A,
  KEYCODE_X,
  KEYCODE_ESCAPE,
  KEYCODE_ENTER,
  KEYCODE_F2,
];
