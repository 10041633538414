import Vue from 'vue';
import _ from 'lodash';
import pluralize from 'pluralize';

Vue.filter('capitalize', (value) => {
  if (!value) return '';
  const string = value.toString();
  return string.charAt(0).toUpperCase() + string.slice(1);
});

Vue.filter('humanize', str => _.capitalize(_.trim(_.snakeCase(str).replace(/_/g, ' '))));
Vue.filter('pluralize', (label, count) => pluralize(label, parseInt(count, 10)));
Vue.filter('singularize', label => pluralize.singular(label));
Vue.filter('stripHtml', (value) => {
  const div = document.createElement('div');
  div.innerHTML = value;
  return div.textContent || '';
});

Vue.filter('truncate', (text = '', length = 30, clamp = '...') => {
  if (text.length <= length) return text;

  let tcText = text.slice(0, length - clamp.length);
  let last = tcText.length - 1;

  while (last > 0 && tcText[last] !== ' ' && tcText[last] !== clamp[0]) last -= 1;

  // Fix for case when text dont have any `space`
  last = last || length - clamp.length;

  tcText = tcText.slice(0, last);

  return tcText + clamp;
});
