import crypto from 'crypto';
import store from '@/store';

export default {
  decrypt(message, key = null) {
    const _key = key || store.getters['auth/user'].id;
    // convert to key to md5
    const hashedKey = crypto.createHash('md5').update(_key.toString()).digest('hex');

    const json = JSON.parse(atob(message));
    const iv = Buffer.from(json.iv, 'base64');
    const value = Buffer.from(json.value, 'base64');

    // Create decipher
    const decipher = crypto.createDecipheriv('aes-256-cbc', hashedKey, iv);

    // Decrypt
    let decrypted = decipher.update(value, 'binary', 'utf8');
    decrypted += decipher.final('utf8');

    return decrypted;
  },
};
