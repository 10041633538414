export const ASSET_TYPE_IMAGE = 'image';
export const ASSET_TYPE_AUDIO = 'audio';
export const ASSET_TYPE_VIDEO = 'video';
export const ASSET_TYPE_BC_VIDEO = 'bc_video';
export const ASSET_TYPE_EMBED = 'embed';
export const ASSET_TYPE_LINK = 'link';
export const ASSET_TYPE_DOC = 'doc';
export const ASSET_TYPE_GLOSSARY_TERM = 'glossary_term';
export const ASSET_TYPE_TEXT = 'text';

export const ASSET_TYPES = [
  ASSET_TYPE_IMAGE,
  ASSET_TYPE_AUDIO,
  ASSET_TYPE_VIDEO,
  ASSET_TYPE_BC_VIDEO,
  ASSET_TYPE_EMBED,
  ASSET_TYPE_LINK,
  ASSET_TYPE_DOC,
  ASSET_TYPE_GLOSSARY_TERM,
  ASSET_TYPE_TEXT,
];
