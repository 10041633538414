import _ from 'lodash';
import api from '@/api';

export default {
  namespaced: true,
  state: {
    namespaces: [],
    fetching: false,
  },
  getters: {
    namespaces: state => state.namespaces,
    keyedNamespaces: state => _.keyBy(state.namespaces, 'key'),
    keyedMetrics: state => _.keyBy(_.flatMap(
      state.namespaces.map(
        ns => ns.metrics.map(m => ({ ...m, namespace: ns.key })),
      ),
    ), m => `${m.namespace}--${m.key}`),
    keyedDimensions: state => _.keyBy(_.flatMap(
      state.namespaces.map(ns => ns.dimensions),
    ), 'key'),
    keyedFilters: state => _.keyBy(_.flatMap(
      state.namespaces.map(ns => ns.filters),
    ), 'key'),
    keyToLabel: (state, getters) => (key, type) => {
      switch (type) {
        case 'namespace':
          return getters.keyedNamespaces[key] ? getters.keyedNamespaces[key].label : key;
        case 'dimension':
          return getters.keyedDimensions[key] ? getters.keyedDimensions[key].label : key;
        case 'metric':
          if (key === 'pod--count') {
            return 'Chapters Streamed or Downloaded ** Legacy **';
          }
          return getters.keyedMetrics[key] ? getters.keyedMetrics[key].label : key;
        case 'filter':
          return getters.keyedFilters[key] ? getters.keyedFilters[key].label : key;
      }
      return key;
    },
  },
  actions: {
    fetchNamespaces({ commit, state }) {
      if (state.fetching || state.namespaces.length > 0) {
        return Promise.resolve();
      }
      commit('setFetchingState', true);
      return api.get('metrics/namespaces', {
        params: {
          with: [
            'filters',
            'metrics',
            'dimensions.combinable_dimensions',
          ],
        },
      }).then(({ data }) => {
        commit('setNamespaces', data.data);
      }).finally(() => {
        commit('setFetchingState', false);
      });
    },
  },
  mutations: {
    setNamespaces(state, payload) {
      state.namespaces = payload;
    },
    setFetchingState(state, payload) {
      state.fetching = payload;
    },
  },
};
