export const TYPE_MAT = 1;
export const TYPE_VIRTUAL_SCHOOL_GROUP = 2;
export const TYPE_BRITISH_COUNCIL = 3;
export const TYPE_TESTING_GROUP = 4;
export const TYPE_OTHER_GROUP = 5;
export const TYPE_AUTOMATED_GROUP = 6;

export const SCHOOL_GROUP_TYPES = [
  { value: TYPE_MAT, text: 'Multi Academy Trust' },
  { value: TYPE_VIRTUAL_SCHOOL_GROUP, text: 'Virtual School' },
  { value: TYPE_BRITISH_COUNCIL, text: 'British Council School' },
  { value: TYPE_TESTING_GROUP, text: 'Testing Group' },
  { value: TYPE_OTHER_GROUP, text: 'Other Group' },
  { value: TYPE_AUTOMATED_GROUP, text: 'Automated Group', disabled: true },
];
