export const CONTENT_PROJECT_STATUS_PLANNED = 1;
export const CONTENT_PROJECT_STATUS_BEING_COMMISSIONED = 2;
export const CONTENT_PROJECT_STATUS_AUTHORING = 3;
export const CONTENT_PROJECT_STATUS_QA = 4;
export const CONTENT_PROJECT_STATUS_IN_HOUSE_EDITING = 5;
export const CONTENT_PROJECT_STATUS_READY_FOR_DESIGN = 6;
export const CONTENT_PROJECT_STATUS_IN_PRODUCTION = 7;
export const CONTENT_PROJECT_STATUS_PUBLISHED = 8;

export const CONTENT_PROJECT_STATUSES = [
  {
    id: CONTENT_PROJECT_STATUS_PLANNED,
    label: 'Planned',
  },
  {
    id: CONTENT_PROJECT_STATUS_BEING_COMMISSIONED,
    label: 'Being commissioned',
  },
  {
    id: CONTENT_PROJECT_STATUS_AUTHORING,
    label: 'Authoring',
  },
  {
    id: CONTENT_PROJECT_STATUS_QA,
    label: 'QA',
  },
  {
    id: CONTENT_PROJECT_STATUS_IN_HOUSE_EDITING,
    label: 'In-house editing',
  },
  {
    id: CONTENT_PROJECT_STATUS_READY_FOR_DESIGN,
    label: 'Ready for design',
  },
  {
    id: CONTENT_PROJECT_STATUS_IN_PRODUCTION,
    label: 'In production',
  },
  {
    id: CONTENT_PROJECT_STATUS_PUBLISHED,
    label: 'Published',
  },
];
