// import api from '@/api';
import _ from 'lodash';
import api from '@/api';
import crypt from '@/helpers/crypt';

export default {
  namespaced: true,
  state: {
    user: undefined,
    error: undefined,
  },
  getters: {
    user: state => state.user,
    error: state => state.error,
    hasAnyPermission: (state, getters) => (
      permissions => _.some(_.map(permissions, permission => getters.hasPermission(permission)))
    ),
    hasPermission: state => (permissionText) => {
      if (!state.user) {
        return false;
      }

      const [module, role] = permissionText.split('.');

      const hasRole = (permission, r) => (
        typeof permission.roles[r] !== 'undefined'
        && permission.roles[r] === true
      );

      const permitted = _.find(state.user.defaultUser.permissions, permission => (
        permission.module === 'admin'
        || (
          permission.module === module
          && (
            hasRole(permission, 'admin') || hasRole(permission, role)
          )
        )
      ));

      return !!(permitted);
    },
  },
  actions: {
    fetch({ commit, state, dispatch }) {
      if (state.user === undefined) {
        api.get('/admin/auth').then(({ data }) => {
          data.data.defaultUser.permissions = JSON.parse(
            crypt.decrypt(data.data.defaultUser.permissions, data.data.id),
          );

          data.data.assistant_allowed = data.data.defaultUser.email.indexOf('@theaccessgroup.com') !== -1;

          commit('setUser', data.data);
          dispatch('featureFlags/initialize', {}, { root: true });
        }).catch(() => {
          commit('setError', 'Failed to fetch user data!');
        });
      }
    },
    sessionExpired({ commit }) {
      commit('setError', 'SessionExpired');
    },
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
    setError(state, data) {
      state.error = data;
    },
  },
};
