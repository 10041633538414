import Vue from 'vue';
import Router from 'vue-router';
import qs from 'qs';
import store from '@/store';

const Dashboard = () => import(/* webpackChunkName: "Dashboard" */'@/views/Dashboard');
const MyDashboard = () => import(/* webpackChunkName: "MyDashboard" */'@/views/Dashboard/MyDashboard');
const ContentDashboard = () => import(/* webpackChunkName: "ContentDashboard" */'@/views/Dashboard/ContentDashboard');
const Content = () => import(/* webpackChunkName: "Content" */'@/views/Content');
const TagGroups = () => import(/* webpackChunkName: "ContentTagGroups" */'@/views/ContentTagGroups');
const Tags = () => import(/* webpackChunkName: "ContentTags" */'@/views/ContentTags');
const Departments = () => import(/* webpackChunkName: "Departments" */'@/views/Departments');
const Subjects = () => import(/* webpackChunkName: "Subjects" */'@/views/Subjects');
const Topics = () => import(/* webpackChunkName: "Topics" */'@/views/Topics');
const Titles = () => import(/* webpackChunkName: "Titles" */'@/views/Titles');
const Workbooks = () => import(/* webpackChunkName: "Workbooks" */'@/views/Workbooks');
const TitleManage = () => import(/* webpackChunkName: "TitleManage" */'@/views/TitleManage');
const Chapters = () => import(/* webpackChunkName: "Chapters" */'@/views/Chapters');
const ExamBoards = () => import(/* webpackChunkName: "ExamBoards" */'@/views/ExamBoards');
const ExamPapers = () => import(/* webpackChunkName: "ExamPapers" */'@/views/ExamPapers');
const ExamPaperManage = () => import(/* webpackChunkName: "ExamPaperManage" */'@/views/ExamPaperManage');
const ChapterManage = () => import(/* webpackChunkName: "ChapterManage" */'@/views/ChapterManage');
const UsageDashboard = () => import(/* webpackChunkName: "UsageDashboard" */'@/views/Dashboard/UsageDashboard');
const RouterGroup = () => import(/* webpackChunkName: "RouterView" */'@/views/RouterGroup');
const Schools = () => import(/* webpackChunkName: "Schools" */'@/views/Schools');
const Users = () => import(/* webpackChunkName: "Users" */'@/views/Users');
const Videos = () => import(/* webpackChunkName: "Videos" */'@/views/Videos');
const VideoIssues = () => import(/* webpackChunkName: "VideoIssues" */'@/views/VideoIssues');
const Transcripts = () => import(/* webpackChunkName: "Transcripts" */'@/views/Transcripts');
const TranscriptManage = () => import(/* webpackChunkName: "TranscriptManage" */'@/views/TranscriptManage');
const AssessGroups = () => import(/* webpackChunkName: "AssessGroups" */'@/views/AssessGroups');
const AssessGroupManage = () => import(/* webpackChunkName: "AssessGroupManage" */'@/views/AssessGroupManage');
const AssessQuestions = () => import(/* webpackChunkName: "AssessQuestions" */'@/views/AssessQuestions');
const Assignments = () => import(/* webpackChunkName: "Assignments" */'@/views/Assignments/Assignments');
const AssignmentQuestionBank = () => import(/* webpackChunkName: "AssignmentQuestionBank" */'@/views/Assignments/AssignmentQuestionBank');
const AssignmentReviewQuestion = () => import(/* webpackChunkName: "AssignmentReviewQuestion" */'@/views/Assignments/AssignmentReviewQuestion');
const AssignmentQuestionIssues = () => import(/* webpackChunkName: "AssignmentQuestionIssues" */'@/views/Assignments/AssignmentQuestionIssues');
const AssignmentManage = () => import(/* webpackChunkName: "AssignmentManage" */'@/views/Assignments/AssignmentManage');
const ContentRequests = () => import(/* webpackChunkName: "ContentRequests" */'@/views/ContentRequests');
const ContentListAccessLogs = () => import(/* webpackChunkName: "ContentListAccessLogs" */'@/views/ContentListAccessLogs');
const PageNotFound = () => import('@/views/PageNotFound');
const SchoolManage = () => import(/* webpackChunkName: "SchoolManage" */'@/views/SchoolManage');
const Assets = () => import(/* webpackChunkName: "Assets" */'@/views/Assets');
const Contracts = () => import(/* webpackChunkName: "Contracts" */'@/views/Contracts');
const Collaborators = () => import(/* webpackChunkName: "Collaborators" */'@/views/Collaborators');
const AdditionalContents = () => import(/* webpackChunkName: "AdditionalContents" */'@/views/AdditionalContents');
const UserManage = () => import(/* webpackChunkName: "UserManage" */'@/views/UserManage');
const SchoolGroupManage = () => import(/* webpackChunkName: "SchoolGroupManage" */'@/views/SchoolGroupManage');
const SchoolGroups = () => import(/* webpackChunkName: "SchoolGroups" */'@/views/SchoolGroups');
const BlockedUsers = () => import(/* webpackChunkName: "BlockedUsers" */'@/views/BlockedUsers');
const PodAutomationTasks = () => import(/* webpackChunkName: "PodAutomationTasks" */'@/views/PodAutomationTasks');
const ContentTree = () => import(/* webpackChunkName: "ContentTree" */'@/views/ContentTree');
const ContentTreeSearch = () => import(/* webpackChunkName: "ContentTreeSearch" */'@/views/ContentTreeSearch');
const ContentStructure = () => import(/* webpackChunkName: "ContentStructure" */'@/views/ContentStructure');
const PodminUsers = () => import(/* webpackChunkName: "PodminUsers" */'@/views/PodminUsers');
const PodminUserManage = () => import(/* webpackChunkName: "PodminUserManage" */'@/views/PodminUserManage');
const Surveys = () => import(/* webpackChunkName: "Surveys" */'@/views/Surveys');
const SurveyManage = () => import(/* webpackChunkName: "SurveyManage" */'@/views/SurveyManage');
const SurveySubmissions = () => import(/* webpackChunkName: "SurveySubmissions" */'@/views/SurveySubmissions');
const CourseManage = () => import(/* webpackChunkName: "CourseManage" */'@/views/CourseManage');
const CoursesList = () => import(/* webpackChunkName: "CourseManage" */'@/views/Courses');
const FeedNotifications = () => import(/* webpackChunkName: "FeedNotifications" */'@/views/FeedNotifications');

Vue.use(Router);

const typeCastId = (route) => {
  const props = { ...route.params };
  if (typeof props.id !== 'undefined') {
    props.id = parseInt(props.id, 10);
  }
  return props;
};

const typeCastContentTreeIds = (route) => {
  const props = { ...route.params };
  if (typeof props.node_id !== 'undefined') {
    props.node_id = parseInt(props.node_id, 10);
  }
  if (typeof props.structure_id !== 'undefined') {
    props.structure_id = parseInt(props.structure_id, 10);
  }
  return props;
};

const r = new Router({
  mode: 'history',
  base: '/',
  routes: [
    { path: '/', name: 'dashboard', component: Dashboard },
    { path: '/my-dashboard', name: 'my_dashboard', component: MyDashboard },
    { path: '/content-dashboard', name: 'content_dashboard', component: ContentDashboard },
    { path: '/cc-dashboard', name: 'cc_dashboard', redirect: '/usage-dashboard' },
    { path: '/usage-dashboard', name: 'usage_dashboard', component: UsageDashboard },
    { path: '/video-issues', name: 'video_issues', component: VideoIssues },
    {
      path: '/content-tree/:structure_id/:node_id?/:path*', name: 'content_tree', component: ContentTree, props: typeCastContentTreeIds,
    },
    {
      path: '/content-tree-search', name: 'content_tree_search', component: ContentTreeSearch, props: typeCastContentTreeIds,
    },
    { path: '/content-structure', name: 'content_structure', component: ContentStructure },

    {
      path: '/users',
      component: RouterGroup,
      children: [
        { path: '/', name: 'users', component: Users },
        {
          path: 'manage/:id?',
          name: 'user_manage',
          component: UserManage,
          props: typeCastId,
          meta: { permissions: ['users.create', 'users.update'] },
        },
      ],
    },
    {
      path: '/schools',
      component: RouterGroup,
      children: [
        { path: '/', name: 'schools', component: Schools },
        {
          path: 'manage/:id?',
          name: 'school_manage',
          component: SchoolManage,
          props: typeCastId,
          meta: { permissions: ['schools.create', 'schools.update'] },
        },
      ],
    },
    // {
    //   path: '/school-templates',
    //   component: RouterGroup,
    //   children: [
    //     { path: '/', name: 'school_templates', component: SchoolTemplates },
    //     {
    //       path: 'manage/:id?', name: 'school_templates_manage', component: SchoolTemplateManage, props: typeCastId,
    //     },
    //   ],
    // },
    {
      path: '/assignments',
      component: RouterGroup,
      children: [
        { path: '', name: 'assignments', component: Assignments },
        {
          path: 'manage/:id?', name: 'assignment_manage', component: AssignmentManage, props: typeCastId,
        },
      ],
      meta: { permissions: ['assignments.read'] },
    },
    {
      path: '/content-tag-groups',
      component: RouterGroup,
      children: [
        { path: '', name: 'content_tag_groups', component: TagGroups },
      ],
    },
    {
      path: '/content-tags',
      component: RouterGroup,
      children: [
        { path: '', name: 'content_tags', component: Tags },
      ],
    },
    {
      path: '/content',
      component: RouterGroup,
      children: [
        { path: '', name: 'contents', component: Content },
      ],
      meta: { permissions: ['pods.read'] },
    },
    {
      path: '/departments',
      component: RouterGroup,
      children: [
        { path: '', name: 'departments', component: Departments },
      ],
      meta: { permissions: ['pods.read'] },
    },
    {
      path: '/subjects',
      component: RouterGroup,
      children: [
        { path: '', name: 'subjects', component: Subjects },
      ],
      meta: { permissions: ['pods.read'] },
    },
    {
      path: '/topics',
      component: RouterGroup,
      children: [
        { path: '', name: 'topics', component: Topics },
      ],
      meta: { permissions: ['pods.read'] },
    },
    {
      path: '/workbooks',
      component: RouterGroup,
      children: [
        { path: '', name: 'workbooks', component: Workbooks },
      ],
      meta: { permissions: ['pods.read'] },
    },
    {
      path: '/titles',
      component: RouterGroup,
      children: [
        { path: '', name: 'titles', component: Titles },
        {
          path: 'manage/:id?',
          name: 'title_manage',
          component: TitleManage,
          props: typeCastId,
          meta: { permissions: ['pods.create', 'pods.update'] },
        },
      ],
      meta: { permissions: ['pods.read'] },
    },
    {
      path: '/chapters',
      component: RouterGroup,
      children: [
        { path: '', name: 'chapters', component: Chapters },
        {
          path: 'manage/:id?',
          name: 'chapter_manage',
          component: ChapterManage,
          props: typeCastId,
          meta: { permissions: ['pods.create', 'pods.update'] },
        },
      ],
      meta: { permissions: ['pods.read'] },
    },
    {
      path: '/exam_boards',
      component: RouterGroup,
      children: [
        { path: '', name: 'exam_boards', component: ExamBoards },
      ],
      meta: { permissions: ['pods.read'] },
    },
    {
      path: '/exam_papers',
      component: RouterGroup,
      children: [
        { path: '', name: 'exam_papers', component: ExamPapers },
        {
          path: 'manage/:id?',
          name: 'exam_paper_manage',
          component: ExamPaperManage,
          props: typeCastId,
          meta: { permissions: ['pods.create', 'pods.update'] },
        },
      ],
      meta: { permissions: ['pods.read'] },
    },
    {
      path: '/question-bank',
      component: RouterGroup,
      children: [
        { path: '', name: 'question_bank', component: AssignmentQuestionBank },
        {
          path: 'questions/:id',
          name: 'question_bank_edit',
          component: AssignmentReviewQuestion,
          props: typeCastId,
        },
      ],
      meta: { permissions: ['assignments.read'] },
    },
    {
      path: '/question-bank-issues',
      name: 'question_bank_issues',
      component: AssignmentQuestionIssues,
      meta: { permissions: ['assignments.read'] },
    },
    {
      path: '/assess/group',
      component: RouterGroup,
      children: [
        { path: '', name: 'assess_groups', component: AssessGroups },
        {
          path: 'manage/:id?', name: 'assess_group_manage', component: AssessGroupManage, props: typeCastId,
        },
      ],
    },
    {
      path: '/assess/questions',
      component: RouterGroup,
      children: [
        { path: '', name: 'assess_questions', component: AssessQuestions },
      ],
    },
    {
      path: '/videos',
      component: RouterGroup,
      children: [
        { path: '', name: 'videos', component: Videos },
      ],
      meta: { permissions: ['pods.read'] },
    },
    {
      path: '/transcripts',
      component: RouterGroup,
      children: [
        { path: '', name: 'transcripts', component: Transcripts },
        {
          path: ':id', name: 'transcript_manage', component: TranscriptManage, props: typeCastId,
        },
      ],
      meta: { permissions: ['transcriptions.read'] },
    },
    {
      path: '/content-requests',
      component: RouterGroup,
      children: [
        { path: '', name: 'content_requests', component: ContentRequests },
      ],
    },
    {
      path: '/content-list/access-logs',
      component: RouterGroup,
      children: [
        { path: '', name: 'content_list_access_logs', component: ContentListAccessLogs },
      ],
    },
    {
      path: '/assets',
      component: RouterGroup,
      children: [
        { path: '', name: 'assets', component: Assets },
      ],
    },
    {
      path: '/surveys',
      component: RouterGroup,
      children: [
        { path: '', name: 'surveys', component: Surveys },
        {
          path: 'manage/:id?', name: 'survey_manage', component: SurveyManage, props: typeCastId,
        },
        {
          path: 'submissions/:id?', name: 'survey_submissions', component: SurveySubmissions, props: typeCastId,
        },
      ],
    },
    {
      path: '/contracts',
      component: RouterGroup,
      children: [
        { path: '', name: 'contracts', component: Contracts },
      ],
    },
    {
      path: '/collaborators',
      component: RouterGroup,
      children: [
        { path: '', name: 'collaborators', component: Collaborators },
      ],
    },
    {
      path: '/additional_contents',
      component: RouterGroup,
      children: [
        { path: '', name: 'additional_contents', component: AdditionalContents },
      ],
    },
    {
      path: '/school-groups',
      component: RouterGroup,
      children: [
        { path: '/', name: 'school_groups', component: SchoolGroups },
        {
          path: 'manage/:id?', name: 'school_group_manage', component: SchoolGroupManage, props: typeCastId,
        },
      ],
      meta: { permissions: ['school_groups.read', 'school_groups.update'] },
    },
    {
      path: '/block-import',
      component: RouterGroup,
      children: [
        { path: '/', name: 'block_import', component: BlockedUsers },
      ],
      meta: { permissions: ['blocked_users.read', 'blocked_users.update'] },
    },
    {
      path: '/automations/pods/tasks',
      component: RouterGroup,
      children: [
        { path: '', name: 'pod_automation_tasks', component: PodAutomationTasks },
      ],
    },
    {
      path: '/podmin_users',
      component: RouterGroup,
      children: [
        { path: '', name: 'podmin_users', component: PodminUsers },
        {
          path: 'manage/:id?', name: 'podmin_users_manage', component: PodminUserManage, props: typeCastId,
        },
      ],
      meta: { permissions: ['default_users.read', 'default_users.update'] },
    },
    {
      path: '/courses',
      component: RouterGroup,
      children: [
        {
          path: '', name: 'courses_list', component: CoursesList,
        },
        {
          path: 'manage/:id?', name: 'course_manage', component: CourseManage, props: typeCastId,
        },
      ],
    },
    {
      path: '/feed_notifications',
      component: RouterGroup,
      children: [
        {
          path: '',
          name: 'feed_notifications',
          component: FeedNotifications,
          meta: { permissions: ['admin'] },
        },
      ],
      meta: { permissions: ['admin'] },
    },
    { path: '*', name: 'not_found', component: PageNotFound },
  ],
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query);

    return result ? (`?${result}`) : '';
  },
});

r.beforeEach((to, from, next) => (
  Promise.all([
    store.waitFor('auth/user'),
  ]).then(() => {
    const permissionDenied = to.matched.filter((route) => !(
      typeof route.meta.permissions === 'undefined'
      || store.getters['auth/hasAnyPermission'](route.meta.permissions)
    ));

    if (permissionDenied.length > 0) {
      next({
        name: 'not_found',
      });
    } else {
      next();
    }
  })
));
r.afterEach(() => {
  store.dispatch('pageLoading/loaded');
});

export default r;
