
export const INTERNAL_RESOURCES_STRUCTURE = 1;
export const STUDENT_RESOURCES_STRUCTURE = 2;
export const TEACHER_RESOURCES_STRUCTURE = 3;
export const STUDENT_AMBASSADOR_RESOURCES_STRUCTURE = 4;
export const INTERNATIONAL_RESOURCES_STRUCTURE = 5;
export const FE_TEACHER_RESOURCE_STRUCTURE = 6;
export const FE_STUDENT_RESOURCE_STRUCTURE = 7;
export const SCOTLAND_TEACHER_RESOURCE_STRUCTURE = 8;
export const SCOTLAND_STUDENT_RESOURCE_STRUCTURE = 9;
export const KS3_RESOURCE_STRUCTURE = 10;

export const STRUCTURE_TYPES = [
  INTERNAL_RESOURCES_STRUCTURE,
  STUDENT_RESOURCES_STRUCTURE,
  TEACHER_RESOURCES_STRUCTURE,
  STUDENT_AMBASSADOR_RESOURCES_STRUCTURE,
  INTERNATIONAL_RESOURCES_STRUCTURE,
  FE_TEACHER_RESOURCE_STRUCTURE,
  FE_STUDENT_RESOURCE_STRUCTURE,
  SCOTLAND_TEACHER_RESOURCE_STRUCTURE,
  SCOTLAND_STUDENT_RESOURCE_STRUCTURE,
  KS3_RESOURCE_STRUCTURE,
];

export const PUBLIC_STRUCTURES = [
  STUDENT_RESOURCES_STRUCTURE,
  TEACHER_RESOURCES_STRUCTURE,
  STUDENT_AMBASSADOR_RESOURCES_STRUCTURE,
  INTERNATIONAL_RESOURCES_STRUCTURE,
  FE_TEACHER_RESOURCE_STRUCTURE,
  FE_STUDENT_RESOURCE_STRUCTURE,
  SCOTLAND_TEACHER_RESOURCE_STRUCTURE,
  SCOTLAND_STUDENT_RESOURCE_STRUCTURE,
];
