import axios from 'axios';
import qs from 'qs';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
// import router from 'router';

axios.defaults.withXSRFToken = true;
axios.defaults.withCredentials = true;

const api = axios.create({
  baseURL: '/api',
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
  },
});

const paramsSerializer = p => qs.stringify(p);

api.interceptors.request.use((request) => {
  if (request.method.toLowerCase() === 'get' && typeof request.paramsSerializer !== 'function') {
    request.paramsSerializer = paramsSerializer;
  }
  return request;
});
api.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 419) {
      store.dispatch('auth/sessionExpired');
    }
    return Promise.reject(error.response);
  },
);

api.getPages = (url, options = {}, page = 1) => {
  const params = {
    ...options.params,
    page,
  };

  return api.get(url, {
    ...options,
    params,
  }).then(({ data }) => {
    if (page < parseInt(data.meta.last_page, 10)) {
      if (typeof options.onProgress === 'function') {
        options.onProgress({
          progress: data.meta.to / data.meta.total * 100,
          total: data.meta.total,
          processed: data.meta.to,
        });
      }
      return api.getPages(url, options, page + 1)
        .then(arr => data.data.concat(arr));
    }

    return data.data;
  });
};

export default api;
