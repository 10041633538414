<template>
  <div class="fixed-bottom fixed ml-4 mb-4 toast-container">
    <div
      v-for="(toast, index) in toasts"
      :key="`toast-${index}`"
      class="toast show"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-header">
        <strong
          class="mr-auto"
        >
          <span>
            <font-awesome-icon
              v-if="toast.status === 'danger'"
              class="text-danger"
              :icon="faExclamationTriangle"
            />
            <font-awesome-icon
              v-else-if="toast.status === 'warning'"
              class="text-warning"
              :icon="faExclamationCircle"
            />
            <font-awesome-icon
              v-else-if="toast.status === 'success'"
              class="text-success"
              :icon="faCheckCircle"
            />
          </span>
          {{ toast.title }}
        </strong>
        <small class="text-muted">
          {{ toast.createdAt | fromNow }}
        </small>
        <button
          type="button"
          class="ml-2 mb-1 close"
          data-dismiss="toast"
          aria-label="Close"
          @click="dismiss(toast)"
        >
          <span aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div
        class="toast-body"
        v-html="toast.message"
      />
    </div>
  </div>
</template>

<script>
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'Toasts',
  data() {
    return {
      faCheckCircle,
      faExclamationCircle,
      faExclamationTriangle,
    };
  },
  computed: {
    toasts() {
      return this.$store.getters['toasts/get'];
    },
  },
  methods: {
    dismiss(toast) {
      return this.$store.dispatch('toasts/dismiss', toast);
    },
  },
};
</script>
<style scoped>
  .toast-container {
    width: 300px;
    z-index: 999999;
  }
</style>
