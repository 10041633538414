import _ from 'lodash';
import api from '@/api';

export default {
  namespaced: true,
  state: {
    items: [],
    loading: false,
    lastFetchedAt: undefined,
  },
  getters: {
    get: state => state.items,
  },
  actions: {
    fetch({ state, commit, rootGetters }, reload = false) {
      if (state.loading
        || (state.items.length > 0
          && !reload
          // reload if last fetch was performed more than 10 minutes ago
          && (Date.now() < ((state.lastFetchedAt || 0) + (10 * 60 * 1000)))
        )
      ) {
        return;
      }
      commit('setLoading', true);
      api.getPages('collaborators', {
        params: {
          with: [
            'role',
          ],
          per_page: 500,
        },
      }).then((data) => {
        const collaborators = _.sortBy(data, 'name').map(c => ({
          ...c,
          label: `${c.id}: ${c.name}`,
        }));
        const userAsCollaborator = collaborators.find(c => c.email === rootGetters['auth/user'].defaultUser.email);
        if (userAsCollaborator) {
          collaborators.splice(collaborators.indexOf(userAsCollaborator), 1);
          userAsCollaborator.label = `${userAsCollaborator.label} (me)`;
          collaborators.unshift(userAsCollaborator);
        }
        commit('setItems', collaborators);
      }).finally(() => {
        commit('setLoading', false);
      });
    },
  },
  mutations: {
    setItems(state, data) {
      state.items = data;
      state.lastFetchedAt = Date.now();
    },
    setLoading(state, data) {
      state.loading = data;
    },
  },
};
