import moment from '@/helpers/moment';

const TOAST_TIMEOUT = 10000;

export default {
  namespaced: true,
  state: {
    toasts: [],
  },
  getters: {
    get: state => state.toasts,
  },
  actions: {
    add({ commit }, payload) {
      payload.createdAt = moment();

      commit('add', payload);

      setTimeout(() => {
        commit('dismiss', payload);
      }, payload.timeout || TOAST_TIMEOUT);
    },
    dismiss({ commit, state }, payload) {
      if (state.toasts.indexOf(payload) !== -1) {
        commit('dismiss', payload);
      }
    },
    flush({ commit }) {
      commit('flush');
    },
  },
  mutations: {
    add(state, alert) {
      state.toasts.push(alert);
    },
    dismiss(state, alert) {
      const index = state.toasts.indexOf(alert);
      if (index !== -1) {
        state.toasts.splice(index, 1);
      }
    },
    flush(state) {
      state.toasts = [];
    },
  },
};
