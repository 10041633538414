import _ from 'lodash';
import api from '@/api';

export default {
  namespaced: true,
  state: {
    items: [],
    loading: false,
  },
  getters: {
    get: state => state.items,
  },
  actions: {
    fetch({ rootGetters, state, commit }, reload = false) {
      if (state.loading
        || (state.items.length > 0 && !reload)
      ) {
        return;
      }
      commit('setLoading', true);
      api.getPages('staff', {
        params: {
          per_page: 500,
        },
      }).then((data) => {
        const staff = _.sortBy(
          data.filter(s => s.salesforce_id !== null).map(s => ({
            ...s,
            label: `${s.first_name} ${s.last_name}`.trim(),
          })),
          'label',
        );
        const currentUserAsStaff = staff.find(s => s.email === rootGetters['auth/user'].defaultUser.email);
        if (currentUserAsStaff) {
          staff.splice(staff.indexOf(currentUserAsStaff), 1);
          currentUserAsStaff.label = `${currentUserAsStaff.label} (me)`;
          staff.unshift(currentUserAsStaff);
        }
        commit('setItems', staff);
      }).finally(() => {
        commit('setLoading', false);
      });
    },
  },
  mutations: {
    setItems(state, data) {
      state.items = data;
    },
    setLoading(state, data) {
      state.loading = data;
    },
  },
};
