<template>
  <nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
    <a
      class="navbar-brand col-sm-3 col-md-2 mr-0"
      href="#"
    >
      Podminster
      <span
        v-if="!isProduction"
        class="text-success font-weight-bold"
      >
        - DEV
      </span>
    </a>
    <div
      v-click-outside="hideResults"
      class="position-relative w-100"
      @click="showResults = true"
    >
      <input
        v-model="searchInput"
        class="form-control form-control-dark w-100"
        type="text"
        placeholder="Search schools"
        aria-label="Search schools"
        :disabled="!canSearchSchools"
        @input="searchDebounced"
      >
      <div
        v-if="showResults && schools.length > 0"
        class="position-absolute"
        style="width: 30rem;"
      >
        <b-list-group>
          <b-list-group-item
            v-for="(s, si) in schools"
            :key="`glob-search-school-${si}`"
            class="d-flex justify-content-between align-items-center pointer"
            @click.stop="navigateToSchoolPage(s.id)"
          >
            {{ s.name }}
            <b-badge
              :variant="s.active ? 'primary' : 'danger'"
              pill
              class="ml-3"
            >
              {{ s.active ? 'Active' : 'Expired' }}
            </b-badge>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
    <ul class="navbar-nav px-3">
      <li class="nav-item text-nowrap">
        <a
          href="/auth/logout"
          class="nav-link pointer"
        >
          Sign out
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import AuthMixin from '@/mixins/AuthMixin';

export default {
  name: 'LayoutHeader',
  mixins: [
    AuthMixin,
  ],
  data() {
    return {
      searchInput: undefined,
      schools: [],
      showResults: true,
    };
  },
  computed: {
    canSearchSchools() {
      return this.hasAnyPermission(['schools.create', 'schools.update']);
    },
    isProduction() {
      return process.env.VUE_APP_ENV === 'production';
    },
  },
  methods: {
    searchDebounced: _.debounce(function searchDebounced() {
      if (this.searchInput) {
        this.$api.get('schools', {
          params: {
            filters: {
              search: this.searchInput,
            },
          },
        }).then(({ data }) => {
          this.schools = data.data.map(s => ({
            id: s.id,
            name: s.display_name || s.name,
            active: moment(s.subscription_end_date).isAfter(moment()),
          }));

          if (!Number.isNaN(parseInt(this.searchInput, 10)) && this.schools.length === 1) {
            // if searching by id, navigate to the school page
            this.navigateToSchoolPage(this.schools[0].id);
          } else {
            this.showResults = true;
          }
        });
      } else {
        this.schools = [];
      }
    }, 500),
    hideResults() {
      this.showResults = false;
    },
    navigateToSchoolPage(schoolId) {
      this.$router.push({ name: 'school_manage', params: { id: schoolId } })
        .catch(() => {});
      this.searchInput = undefined;
      this.showResults = false;
      this.schools = [];
    },
  },
};
</script>
