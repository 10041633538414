export default {
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    baseURL() {
      return process.env.VUE_APP_ROOT_FRONTEND;
    },
    isAssistantEnabled() {
      return this.$store.getters['featureFlags/ASSISTANT_ENABLED'] && this.user && this.user.assistant_allowed;
    },
  },
  methods: {
    hasPermission(permission) {
      return this.$store.getters['auth/hasPermission'](permission);
    },
    hasAnyPermission(permissions) {
      return this.$store.getters['auth/hasAnyPermission'](permissions);
    },
    async loginAsSchool(school) {
      const adminUser = school.admin_users.length > 0
        ? school.admin_users[0]
        // a put on school endpoint, automatically creates admin user if missing...
        : await this.$api.put(`/schools/${school.id}`)
          .then(({ data }) => data.data.admin_users[0]);

      if (adminUser) {
        this.loginAsUser(adminUser.id, false);
      } else {
        this.$bvModal.msgBoxOk('This school does not have an assigned admin user to login to!');
      }
    },
    async loginAsUser(userId, needsConfirmation = true) {
      const confirmed = !needsConfirmation ? true
        : await this.$bvModal.msgBoxConfirm(
          'Please confirm that you wish to assume the identity of this user, '
          + 'and that you accept full responsibility for any actions performed when logged in as user.',
          {
            title: 'Please Confirm',
            size: 'md',
            buttonSize: 'sm',
            headerBgVariant: 'danger',
            headerTextVariant: 'white',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            centered: true,
          },
        );

      if (confirmed) {
        try {
          const { token, website } = await this.$api.post('/admin/auth/assume', {
            user_id: userId,
          }).then(({ data }) => data);

          const form = document.createElement('form');
          const userIdInput = document.createElement('input');
          userIdInput.name = 'user_id';
          userIdInput.value = userId;

          form.append(userIdInput);

          const tokenInput = document.createElement('input');
          tokenInput.name = 'token';
          tokenInput.value = token;
          form.append(tokenInput);
          form.action = `${this.getURLFromWebsite(website)}podauth/sso/assume`;
          form.target = '_blank';

          document.body.append(form);
          form.submit();
          document.body.removeChild(form);
        } catch (e) {
          this.$store.dispatch('toasts/add', {
            status: 'warning',
            title: 'Failed to authenticate',
            message: 'Invalid user ID provided.',
          });
        }
      }
    },
    getURLFromWebsite(websiteName) {
      switch (websiteName) {
        case 'gcsepod':
          return process.env.VUE_APP_ROOT_FRONTEND;
      }
      return process.env.VUE_APP_ROOT_FRONTEND_EOD;
    },
  },
};
