<template>
  <div
    ref="container"
    class="react-wrapper"
  />
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { createElement } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createRoot } from 'react-dom/client';

export default {
  inheritAttrs: false,
  props: {
    component: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      reactRoot: null,
    };
  },
  watch: {
    $attrs: {
      deep: true,
      handler() {
        this.updateReactComponent();
      },
    },
  },
  mounted() {
    this.reactRoot = createRoot(this.$refs.container);
    this.updateReactComponent();
  },
  destroyed() {
    this.reactRoot.unmount();
  },
  methods: {
    updateReactComponent() {
      this.reactRoot.render(createElement(this.component, this.$attrs));
    },
  },
};
</script>
