export default {
  namespaced: true,
  state: {
    ASSISTANT_ENABLED: false,
    status: undefined,
  },
  getters: {
    ASSISTANT_ENABLED: state => state.ASSISTANT_ENABLED,
  },
  actions: {
    async initialize({ commit, state, rootGetters }, force = false) {
      if (state.status !== 'initializing' && (state.status !== 'initialized' || force)) {
        commit('setStatus', 'initializing');

        try {
          await this._vm.$ld.waitForInitialization();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
          commit('setStatus', undefined);
          return;
        }

        this._vm.$ld.identify({
          kind: 'user',
          key: rootGetters['auth/user'].defaultUser.id,
        }).then(() => {
          commit('setFlagValue', {
            key: 'ASSISTANT_ENABLED',
            value: process.env.VUE_APP_ENV !== 'production'
              || this._vm.$ld.variation('PODMIN_ASSISTANT_ENABLED', false),
          });

          commit('setStatus', 'initialized');
        }).catch(() => {
          commit('setStatus', undefined);
        });
      }
    },
  },
  mutations: {
    setStatus(state, value) {
      state.initialized = value;
    },
    setFlagValue(state, { key, value }) {
      state[key] = value;
    },
  },
};
