import _ from 'lodash';
import api from '@/api';

const cacheableTagGroups = [
  'difficulty',
  'calculator',
  'issues',
  'ao',
];

export default {
  namespaced: true,
  state: {
    groups: {
      items: [],
      loading: false,
      lastFetchedAt: undefined,
    },
  },
  getters: {
    groups: state => state.groups.items,
    loading: state => state.groups.loading,
  },
  actions: {
    async fetchTagGroups({ state, commit }, options = { force: false }) {
      if (state.groups.loading
        || (state.groups.items.length > 0
          && !options.force
          // reload if it's been more than 30 minutes since last fetch
          && (Date.now() < ((state.groups.lastFetchedAt || 0) + (30 * 60 * 1000)))
        )
      ) {
        return;
      }
      commit('setTagGroupsLoading', true);

      const groups = await api.getPages('content/tags/groups', {
        params: {
          per_page: 100,
        },
      }).then((data) => {
        const keyedGroups = _.keyBy(data.map(g => ({
          ...g,
          label: g.name,
          cacheable: cacheableTagGroups.indexOf(g.name.toLowerCase()) !== -1 || g.tag_type === 'boolean',
        })), 'id');

        const cacheables = Object.values(keyedGroups).filter(g => g.cacheable);

        if (cacheables.length > 0) {
          // fetch all tags for cacheable items
          return api.getPages('content/tags', {
            params: {
              filters: {
                groups: cacheables.map(g => g.id),
              },
              per_page: 200,
            },
          }).then((tags) => {
            tags.forEach((t) => {
              if (!keyedGroups[t.group_id].tags) {
                keyedGroups[t.group_id].tags = [];
              }
              keyedGroups[t.group_id].tags.push({ ...t, label: t.name });
            });
            return Object.values(keyedGroups);
          });
        }
        return Object.values(keyedGroups);
      });

      commit('setTagGroups', groups);
      commit('setTagGroupsLoading', false);
    },
  },
  mutations: {
    setTagGroups(state, data) {
      state.groups.items = data;
      state.groups.lastFetchedAt = Date.now();
    },
    setTagGroupsLoading(state, value) {
      state.groups.loading = value;
    },
  },
};
