import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ClickOutside from 'vue-click-outside';
import VModal from 'vue-js-modal';
import BootstrapVue from 'bootstrap-vue';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import VCalendar from 'v-calendar';
import IdleVue from 'idle-vue';

import crypt from '@/helpers/crypt';
import ReactWrapper from '@/components/ReactWrapper';
import axios from 'axios';
import App from './App';
import router from './router';
import store from './store';
// import './registerServiceWorker';
import './scss/tw.css';
import '@ed/ed-ui/dist/ed-ui.css';
import './scss/app.scss';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import 'katex/dist/katex.min.css';
import 'mathlive/static.css';

import api from './api';
// import apiMain from './apiMain';
import '@/helpers/string';

// eslint-disable-next-line import/no-extraneous-dependencies
const LDClient = require('launchdarkly-js-client-sdk');

Vue.use(VModal);
Vue.use(BootstrapVue);
Vue.use(VCalendar, {
  locale: 'en-GB',
});
Vue.use(IdleVue, {
  eventEmitter: new Vue(),
  idleTime: 60 * 10 * 1000, // 10 minutes
});

Vue.component('Pass', {
  render() {
    return this.$scopedSlots.default(this.$attrs);
  },
});
Vue.component('ReactWrapper', ReactWrapper);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.directive('click-outside', ClickOutside);
Vue.filter('numberToLocale', (value) => {
  if (value && !Number.isNaN(value)) {
    return value.toLocaleString();
  }
  return 0;
});
Vue.filter('assetLocation', (url) => {
  if (url && !(/^http/).test(url)) {
    return `https://s3-eu-west-1.amazonaws.com/cdn.gcsepod.com/assets/${url.replace(/^\//, '').replace(/^assets\//, '')}`;
  }
  return url;
});

Vue.filter('cdnLocation', (url) => {
  if (url && !(/^http/).test(url)) {
    return `https://d28m2x7crzpr7u.cloudfront.net/cdn.gcsepod.com/${url.replace(/^\//, '')}`;
  }
  return url;
});

Vue.prototype.$ld = LDClient.initialize(process.env.VUE_APP_LD_ENV_KEY, {
  kind: 'user',
  key: 'anonymous',
});

Vue.prototype.$api = api;
// Vue.prototype.$apiMain = apiMain;
Vue.prototype.$crypt = crypt;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  data() {
    return {
      intervalId: undefined,
    };
  },
  async created() {
    this.$store.dispatch('pageLoading/loading');
    await axios.get('/csrf');
    try {
      await axios.get('/auth/check');
      this.$store.dispatch('auth/fetch');
    } catch (e) {
      if (e.response && (e.response.status === 401 || e.response.status === 419)) {
        const redirectUri = `${window.location.pathname}${window.location.search || ''}`;
        window.location = `/auth/start${redirectUri !== '/' ? `?rd=${encodeURIComponent(redirectUri)}` : ''}`;
      }
      return;
    }

    this.monitorSession();
  },
  onActive() {
    this.monitorSession();
  },
  onIdle() {
    this.stopSessionMonitoring();
  },
  destroyed() {
    this.stopSessionMonitoring();
  },
  methods: {
    monitorSession() {
      if (!this.intervalId) {
        // check and extend session every 2 minutes
        this.intervalId = setInterval(() => {
          axios.get('/auth/check').catch((e) => {
            if (e && e.response && (e.response.status === 401 || e.response.status === 419)) {
              this.$store.dispatch('auth/sessionExpired');
            }
          });
        }, 2 * 60 * 1000);
      }
    },
    stopSessionMonitoring() {
      if (this.intervalId !== undefined) {
        clearInterval(this.intervalId);
        this.intervalId = undefined;
      }
    },
  },
  render: (h) => h(App),
}).$mount('#app');
