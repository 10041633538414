<template>
  <nav class="col-md-2 d-none d-md-block bg-light sidebar">
    <div class="sidebar-sticky">
      <!--      <section-->
      <!--        v-if="hasPermission('user-management.read')"-->
      <!--      >-->
      <!--<h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">-->
      <!--          <span>User Management</span>-->
      <!--        </h6>-->
      <!--        <ul class="nav flex-column mb-2">-->
      <!--          <li class="nav-item">-->
      <!--            <router-link-->
      <!--              :to="{ name: 'schools' }"-->
      <!--              class="nav-link"-->
      <!--              active-class="active"-->
      <!--            >-->
      <!--              Schools-->
      <!--            </router-link>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </section>-->

      <section>
        <router-link
          :to="{ name: 'dashboard' }"
          class="nav-link"
          active-class="active"
          exact
        >
          <h6 class="sidebar-heading mb-0">
            Dashboard
          </h6>
        </router-link>
        <ul class="nav flex-column mb-2 ml-2">
          <li class="nav-item">
            <router-link
              :to="{ name: 'my_dashboard' }"
              class="nav-link"
              active-class="active"
            >
              My Dashboard
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'usage_dashboard' }"
              class="nav-link"
              active-class="active"
            >
              Usage
            </router-link>
          </li>
          <li
            v-if="hasPermission('pods.read')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'content_dashboard' }"
              class="nav-link"
              active-class="active"
            >
              Content
            </router-link>
          </li>
        </ul>
      </section>

      <section v-if="hasAnyPermission(['assignments.read', 'assess.read'])">
        <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Assess</span>
        </h6>
        <ul class="nav flex-column mb-2 ml-2">
          <li
            v-if="hasPermission('assignments.read')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'assignments' }"
              class="nav-link"
              active-class="active"
            >
              Assignments
            </router-link>
          </li>
          <li
            v-if="hasPermission('assignments.read')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'question_bank' }"
              class="nav-link"
              active-class="active"
            >
              Question Bank
            </router-link>
            <router-link
              :to="{ name: 'question_bank_issues' }"
              class="nav-link"
              active-class="active"
            >
              Question Bank Issues
            </router-link>
          </li>
          <li
            v-if="hasPermission('assess.read')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'assess_groups' }"
              class="nav-link"
              active-class="active"
            >
              Assess Groups
            </router-link>
          </li>
          <li
            v-if="hasPermission('assess.read')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'assess_questions' }"
              class="nav-link"
              active-class="active"
            >
              Assess Questions
            </router-link>
          </li>
        </ul>
      </section>

      <section v-if="hasAnyPermission(['pods.read', 'transcriptions.read'])">
        <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Content</span>
        </h6>
        <ul class="nav flex-column mb-2 ml-2">
          <li
            v-if="hasPermission('pods.read')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'contents' }"
              class="nav-link"
              active-class="active"
            >
              Manage
            </router-link>
          </li>
          <li
            v-if="hasPermission('pods.read')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'chapters' }"
              class="nav-link"
              active-class="active"
            >
              Pods
            </router-link>
          </li>
          <li
            v-if="hasPermission('pods.read')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'courses_list' }"
              class="nav-link"
              active-class="active"
            >
              Courses
            </router-link>
          </li>
          <li
            v-if="hasPermission('transcriptions.read')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'videos' }"
              class="nav-link"
              active-class="active"
            >
              Videos
            </router-link>
          </li>
          <li
            v-if="hasPermission('transcriptions.read')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'video_issues' }"
              class="nav-link"
              active-class="active"
            >
              Video Issues
            </router-link>
          </li>
          <li
            v-if="hasPermission('pods.read')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'contracts' }"
              class="nav-link"
              active-class="active"
            >
              Contracts
            </router-link>
          </li>
          <li
            v-if="hasPermission('admin')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'collaborators' }"
              class="nav-link"
              active-class="active"
            >
              Collaborators
            </router-link>
          </li>
          <li
            v-if="hasPermission('pods.read')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'assets' }"
              class="nav-link"
              active-class="active"
            >
              Asset Store
            </router-link>
          </li>
          <li
            v-if="hasPermission('transcriptions.read')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'transcripts' }"
              class="nav-link"
              active-class="active"
            >
              Transcripts
            </router-link>
          </li>
          <li
            v-if="hasPermission('pods.read')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'pod_automation_tasks' }"
              class="nav-link"
              active-class="active"
            >
              Pod Automation Tasks
            </router-link>
          </li>
          <li
            v-if="hasPermission('admin')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'content_requests' }"
              class="nav-link"
              active-class="active"
            >
              Content Requests
            </router-link>
          </li>
          <li
            v-if="hasPermission('admin')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'content_list_access_logs' }"
              class="nav-link"
              active-class="active"
            >
              Content List - Access Logs
            </router-link>
          </li>
          <li
            class="nav-item"
          >
            <router-link
              :to="{ name: 'content_structure'}"
              class="nav-link"
              active-class="active"
            >
              Content Structure
            </router-link>
          </li>
          <li
            v-if="hasPermission('surveys.read')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'surveys'}"
              class="nav-link"
              active-class="active"
            >
              Surveys
            </router-link>
          </li>
          <li
            v-if="hasPermission('admin')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'content_tag_groups' }"
              class="nav-link"
              active-class="active"
            >
              Tag Groups
            </router-link>
          </li>
          <li
            v-if="hasPermission('admin')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'content_tags' }"
              class="nav-link"
              active-class="active"
            >
              Tags
            </router-link>
          </li>
        </ul>
      </section>

      <section v-if="hasAnyPermission(['schools.read'])">
        <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>SUBSCRIPTION</span>
        </h6>
        <ul class="nav flex-column mb-2 ml-2">
          <li
            v-if="hasPermission('schools.read')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'schools' }"
              class="nav-link"
              active-class="active"
            >
              Schools
            </router-link>
            <!--            <router-link-->
            <!--              :to="{ name: 'school_templates', query: {owner: [user.defaultUser.id]}}"-->
            <!--              class="nav-link"-->
            <!--              active-class="active"-->
            <!--            >-->
            <!--              School Templates-->
            <!--            </router-link>-->
            <router-link
              :to="{ name: 'school_groups' }"
              class="nav-link"
              active-class="active"
            >
              School Groups
            </router-link>
          </li>
        </ul>
      </section>
      <section v-if="hasAnyPermission(['schools.read'])">
        <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>USER</span>
        </h6>
        <ul class="nav flex-column mb-2 ml-2">
          <li v-if="hasPermission('users.read')">
            <router-link
              :to="{ name: 'users' }"
              class="nav-link"
              active-class="active"
            >
              Users
            </router-link>
          </li>
          <li v-if="hasPermission('block_import.read')">
            <router-link
              :to="{ name: 'block_import' }"
              class="nav-link"
              active-class="active"
            >
              Block MIS Imports
            </router-link>
          </li>
        </ul>
      </section>
      <section v-if="hasAnyPermission(['admin.read'])">
        <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Admin</span>
        </h6>
        <ul class="nav flex-column mb-2 ml-2">
          <li
            v-if="hasPermission('default_users.read')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'podmin_users' }"
              class="nav-link"
              active-class="active"
            >
              Podmin User Admin
            </router-link>
          </li>
        </ul>
      </section>
    </div>
  </nav>
</template>

<script>
import AuthMixin from '@/mixins/AuthMixin';
import { INTERNAL_RESOURCES_STRUCTURE } from '@/constants';

export default {
  name: 'LeftNavigation',
  mixins: [AuthMixin],
  data() {
    return {
      INTERNAL_RESOURCES_STRUCTURE,
    };
  },
};
</script>
